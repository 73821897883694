<template>
  <div>
    <h4>{{ indDisplayName }}</h4>
    <div class="info">
      <p>
        {{ profile.address_display_line1 }}
        <br v-if="profile.address_display_line1" />
        {{ profile.address_display_line2 }}
        <br v-if="profile.address_display_line2" />
        {{ profile.phone }}
        <span v-if="profile.email && profile.phone">|</span>
        <a :href="'mailto:' + profile.email">{{ profile.email }}</a>
      </p>
      <img
        v-if="!!profile.image_url_path && profile.image_url_path !== ''"
        :src="profile.image_url_path"
        class="profile_image"
      />
      <camera v-if="!profile.image_url_path || profile.image_url_path === ''"></camera>
      <div v-if="showUpload">
        <b-form-file
          class="mb-1 mt-1"
          style="text-transform: uppercase"
          ref="fileUploadImg"
          v-model.lazy="individual_image_file"
          name="individual_image"
          :placeholder="i18n['profile-contact-info'].tcImageOptional"
          :drop-placeholder="i18n['profile-contact-info'].tcDropFileHere"
          :browse-text="i18n['profile-contact-info'].tcBrowse"
        />
        <button @click="onUpload" variant="primary" size="sm" class="btn btn-primary">
          {{ i18n['profile-contact-info'].tcUploadPhoto }}
        </button>
        <button @click="onCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
          {{ i18n['profile-contact-info'].tcCancel }}
        </button>
      </div>
      <div v-if="okToEditProfile || iCanSeeAny(secured_controls)">
        <b-link @click="showUpload = true" v-if="!showUpload">
          <span v-if="!profile.image_url_path">{{ i18n['profile-contact-info'].tcAddPhoto }}</span>
          <span v-if="profile.image_url_path">{{ i18n['profile-contact-info'].tcUpdatePhoto }}</span>
        </b-link>
        <b-link @click="onDelete" v-if="profile.image_url_path && !showUpload" class="ml-2">{{
          i18n['profile-contact-info'].tcDeletePhoto
        }}</b-link>
      </div>
    </div>
  </div>
</template>

<script>
import camera from '@/assets/svgs/camera.vue'
import { mapActions, mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

/* eslint-disable */
export default {
  name: 'profile-contact-info',
  mixins: [translationMixin],
  props: {
    userKey: {
      type: String,
      default: '',
    },
    profile: {
      formal_name: {
        type: String,
        default: '',
      },
      address_display_line1: {
        type: String,
        default: '',
      },
      address_display_line2: {
        type: String,
        default: '',
      },
      phone: {
        type: String,
        default: '',
      },
      email: {
        type: String,
        default: '',
      },
      image_url_path: {
        type: String,
        default: '',
      },
    },
  },
  data() {
    return {
      i18n: { 'profile-contact-info': {} },
      individual_image_file: null,
      showUpload: false,
      secured_controls: {
        update_member_profile_photo_link: 'a17c96a0-03b4-43ef-9679-14c0248c0296',
        delete_member_profile_photo_link: '2b3610de-c2f5-4afe-a95f-66391bff95c6',
      },
    }
  },
  async created() {
    this.i18n = await this.getComponentTranslations('profile-contact-info')
  },
  methods: {
    ...mapActions({
      savePhoto: 'membership/savePhoto',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    onFileChanged(event) {
      this.individual_image_file = event.target.files[0]
    },
    async onUpload() {
      if (this.individual_image_file === null) {
        this.$swal({
          icon: 'error',
          text: this.i18n['profile-contact-info'].tcImageIsRequired,
          confirmButtonText: this.i18n['profile-contact-info'].tcOk || 'Ok',
        }).then((result) => {
          return
        })
        return
      } else {
        var payload = {
          iim_ind_key: this.profile.ind_key,
          iim_image: null,
          iim_user: this.userIndKey,
          iim_delete_flag: false,
        }
        await (payload.iim_image = this.individual_image_file)
        await this.saveProcess(
          payload,
          this.i18n['profile-contact-info'].tcPhotoWasSaved,
          this.i18n['profile-contact-info'].tcAnErrorOccurred
        )
      }
    },
    onCancel() {
      this.individual_image_file = null
      this.showUpload = false
    },
    async onDelete() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.i18n['profile-contact-info'].tcPhotoWillBeDeleted,
        confirmButtonText: this.i18n['profile-contact-info'].tcOk || 'Ok',
        cancelButtonText: this.i18n['profile-contact-info'].tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.deleteThePhoto()
        }
      })
    },
    async deleteThePhoto() {
      var payload = {
        iim_ind_key: this.profile.ind_key,
        iim_user: this.userIndKey,
        iim_delete_flag: true,
      }
      await this.saveProcess(
        payload,
        this.i18n['profile-contact-info'].tcPhotoWasDeleted,
        this.i18n['profile-contact-info'].tcDeleteError
      )
    },
    async saveProcess(payload, msg, errmsg) {
      try {
        await Promise.all([this.setLoadingStatus(true), await this.savePhoto(payload)]).then((result) => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? msg : errmsg,
            confirmButtonText: this.i18n['profile-contact-info'].tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              this.showUpload = false
              this.$emit('reloadProfile')
            }
          })
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
  },
  computed: {
    ...mapGetters({
      iCanSeeAny: 'user/iCanSeeAny',
      okToEditProfile: 'user/okToEditProfile',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
    }),
    indDisplayName() {
      return !!this.profile.informal_name && this.profile.preferred_name === 'I'
        ? `${this.profile.prefix} ${this.profile.informal_name} ${this.profile.last_name}`
        : this.profile.formal_name
    },
  },
  components: {
    camera: camera,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';
.profile_image {
  max-width: 285px;
  max-height: 185px;
  object-fit: contain;
}
</style>
